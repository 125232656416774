import React from 'react';
import * as icons from './constants';
import { StyledIcon } from './styled';

const Icon = ({
    hoverRoundedColor,
    roundedColor,
    outlineColor = 'transparent',
    roundedSize,
    roundedOpacity = 1,
    color,
    stroke,
    size,
    ...props
}) => {
    return (
        <StyledIcon
            $roundedColor={roundedColor}
            $roundedSize={roundedSize}
            $outlineColor={outlineColor}
            $roundedOpacity={roundedOpacity}
            $hoverRoundedColor={hoverRoundedColor}
            $color={color}
            $stroke={stroke}
            $size={size}
            {...props}
        />
    );
};

Icon.AmexIcon = icons.AmexIcon;
Icon.BoxIcon = icons.BoxIcon;
Icon.NimbusIcon = icons.NimbusIcon;
Icon.KlicklyConnectorLogo = icons.KlicklyConnectorLogo;
Icon.ArchiveIcon = icons.ArchiveIcon;
Icon.CarbonIcon = icons.CarbonIcon;
Icon.ArrowBackIcon = icons.ArrowBack;
Icon.ArrowChevronDownIcon = icons.ArrowChevronDown;
Icon.AwaIcon = icons.Awa;
Icon.ArrowChevronLeftIcon = icons.ArrowChevronLeft;
Icon.ArrowChevronRightIcon = icons.ArrowChevronRight;
Icon.ArrowChevronUpIcon = icons.ArrowChevronUp;
Icon.ArrowDownIcon = icons.ArrowDown;
Icon.ArrowForwardIcon = icons.ArrowForward;
Icon.ArrowUpIcon = icons.ArrowUp;
Icon.CalendarIcon = icons.CalendarIcon;
Icon.StripeIcon = icons.StripeIcon;
Icon.CheckCircleIcon = icons.CheckCircleIcon;
Icon.CloseCircleIcon = icons.CloseCircleIcon;
Icon.CartIcon = icons.CartIcon;
Icon.ChartIcon = icons.ChartIcon;
Icon.CheckIcon = icons.CheckIcon;
Icon.CloneIcon = icons.CloneIcon;
Icon.CloseIcon = icons.CloseIcon;
Icon.ColorPreviewIcon = icons.ColorPreviewIcon;
Icon.DiscoverIcon = icons.DiscoverIcon;
Icon.DownloadIcon = icons.DownloadIcon;
Icon.EditIcon = icons.EditIcon;
Icon.EyeIcon = icons.EyeIcon;
Icon.ImageIcon = icons.ImageIcon;
Icon.InfoIcon = icons.InfoIcon;
Icon.InfoFilledIcon = icons.InfoFilledIcon;
Icon.KeyIcon = icons.KeyIcon;
Icon.LockIcon = icons.LockIcon;
Icon.LocationIcon = icons.LocationIcon;
Icon.LinearChartIcon = icons.LinearChartIcon;
Icon.ArrowRightLong = icons.ArrowRightLong;
Icon.LockIconRestyling = icons.LockIconRestyling;
Icon.MastercardIcon = icons.MastercardIcon;
Icon.MinusIcon = icons.MinusIcon;
Icon.NotificationIcon = icons.NotificationIcon;
Icon.NotificationIncomingIcon = icons.NotificationIncomingIcon;
Icon.PauseIcon = icons.PauseIcon;
Icon.PersonIcon = icons.PersonIcon;
Icon.PlayIcon = icons.PlayIcon;
Icon.PlusIcon = icons.PlusIcon;
Icon.PendingCircleIcon = icons.PendingCircleIcon;
Icon.QuestionIcon = icons.QuestionIcon;
Icon.ResetIcon = icons.ResetIcon;
Icon.SearchIcon = icons.SearchIcon;
Icon.StopIcon = icons.StopIcon;
Icon.TableIcon = icons.TableIcon;
Icon.TrashIcon = icons.TrashIcon;
Icon.VisaIcon = icons.VisaIcon;
Icon.AddMoreProductsIcon = icons.AddMoreProductsIcon;
Icon.AnalyzePerformanceIcon = icons.AnalyzePerformanceIcon;
Icon.BestsellersIcon = icons.BestsellersIcon;
Icon.BestsellersSmallIcon = icons.BestsellersSmallIcon;
Icon.CampaignIcon = icons.CampaignIcon;
Icon.CollectionsIcon = icons.CollectionsIcon;
Icon.ContactCenterIcon = icons.ContactCenterIcon;
Icon.CreditCardIcon = icons.CreditCardIcon;
Icon.EntireCatalogIcon = icons.EntireCatalogIcon;
Icon.FacebookIcon = icons.FacebookIcon;
Icon.BrandsLogoIcon = icons.BrandsLogoIcon;
Icon.LinkedInIcon = icons.LinkedInIcon;
Icon.MobilePhoneIcon = icons.MobilePhoneIcon;
Icon.PerformanceIcon = icons.PerformanceIcon;
Icon.ProductTypesIcon = icons.ProductTypesIcon;
Icon.SpecificProductsIcon = icons.SpecificProductsIcon;
Icon.TwitterIcon = icons.TwitterIcon;
Icon.SettingsIcon = icons.SettingsIcon;
Icon.FilterIcon = icons.FilterIcon;
Icon.QuestionCircleIcon = icons.QuestionCircleIcon;
Icon.SpinnerIcon = icons.SpinnerIcon;
Icon.DatePickerIcon = icons.DatePickerIcon;
Icon.HintIcon = icons.HintIcon;
Icon.KlicklyLogo = icons.KlicklyLogo;
Icon.ShopifyLogo = icons.ShopifyLogo;
Icon.RechargeLogo = icons.RechargeLogo;
Icon.BigCommerceIcon = icons.BigCommerceIcon;
Icon.LoopIcon = icons.LoopIcon;
Icon.SearchAds = icons.SearchAds;
Icon.ProductNoImageIcon = icons.ProductNoImageIcon;
Icon.ImgLandscape = icons.ImgLandscape;
Icon.CustomCreatives = icons.CustomCreatives;
Icon.UploadIcon = icons.UploadIcon;

export { Icon };
