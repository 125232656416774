const COOKIE_CONSENT = 'cookie_consent';

export const cookieConsent = {
    setCookie(value) {
        try {
            localStorage.setItem(COOKIE_CONSENT, value);
        } catch (error) {
            console.error('Failed to set cookie:', error); //eslint-disable-line
        }
    },
    getCookie() {
        try {
            const data = localStorage.getItem(COOKIE_CONSENT);
            return data || null;
        } catch (error) {
            console.error('Failed to get cookie:', error); //eslint-disable-line
            return null;
        }
    },
    hasCookiePreference() {
        const consent = this.getCookie();
        return Boolean(consent);
    }
};
