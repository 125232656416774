import { COLORS } from '@constants';
import { Icon } from '@ui';
import { Upload } from 'antd';
import styled, { css, keyframes } from 'styled-components';

const rotateAnimation = keyframes`
    0% { transform: rotate(360deg) }
    100% { transform: rotate(0deg) }
`;

export const StyledStatusWrapper = styled.div`
    width: 100%;
    padding: 48px 24px;
    border: 1px solid ${COLORS.$gray15Black};
    border-radius: 8px;
    margin-bottom: 24px;

    ${({ $error }) =>
        $error
            ? css`
                  border-color: ${COLORS.$error};
              `
            : ''}
`;

export const StyledIcon = styled(Icon)`
    ${({ $pending }) =>
        $pending
            ? css`
                  animation-name: ${rotateAnimation};
                  animation-duration: 1s;
                  animation-iteration-count: infinite;
              `
            : ''}
`;

export const StyledUpload = styled(Upload)`
    ${({ $pending }) =>
        $pending
            ? css`
                  touch-action: none;
                  pointer-events: none;
              `
            : ''}
`;
