import { COLORS } from '@constants';
import { Flex } from 'antd';
import styled from 'styled-components';

export const StyledCookieWrapper = styled.div`
    height: 88px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 103;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
`;

export const StyledContent = styled(Flex)`
    padding: 0 80px;
    color: ${COLORS.$white};
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    button {
        color: ${COLORS.$white};
    }

    a {
        text-decoration: underline;

        &:hover {
            color: ${COLORS.$white};
        }
    }
`;
