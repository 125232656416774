import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button, Icon, Typography } from '@ui';
import { isIframe } from '@utils';
import { Flex } from 'antd';
import { useCookieConsent } from '../../hooks/useCookieConsent';
import { StyledContent, StyledCookieWrapper } from './styled';

const ConsentNotification = ({ children }) => {
    const { showNotification, rejectCookies, acceptCookies } = useCookieConsent();

    // don't show cookie consent in shopify/bc embedded app
    if (!showNotification || isIframe()) return children;

    return (
        <React.Fragment>
            {children}
            <StyledCookieWrapper>
                <StyledContent align='center' gap={24} justify='space-between'>
                    <Flex align='center' gap={10}>
                        <Button
                            onClick={() => rejectCookies()}
                            type='noStyle'
                            padding='0'
                            style={{ background: 'transparent' }}
                            icon={
                                <Icon
                                    size='24px'
                                    color={COLORS.$white}
                                    component={() => <Icon.CloseIcon size='24px' />}
                                />
                            }
                        />
                        <Typography.Text type='body2' color={COLORS.$white}>
                            This site uses cookies. For more information about cookies and how to disable them, please
                            refer to the{' '}
                            <Link to={ROUTE_PATHS.PRIVACY} target='_blank' rel='noopener noreferrer'>
                                site privacy policy
                            </Link>
                            .
                        </Typography.Text>
                    </Flex>
                    <Button padding='10px 22px' onClick={() => acceptCookies()}>
                        Got It
                    </Button>
                </StyledContent>
            </StyledCookieWrapper>
        </React.Fragment>
    );
};

export default ConsentNotification;
