export const ROUTE_PATHS = {
    ROOT: '/',
    WELCOME: '/welcome',
    DOCS: '/docs/api',
    REQUEST_ACCESS: '/request-access',
    LOGIN: '/login',
    REGISTER: '/register',
    CONNECTOR: '/connector',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_PASSWORD_SENT: '/forgot-password/sent',
    FORGOT_PASSWORD_RESET: '/forgot-password/reset',
    FORGOT_PASSWORD_SUCCESS: '/forgot-password/success',
    UNSUBSCRIBE: '/unsubscribe/:token',
    UNSUBSCRIBE_COMPLETE: '/unsubscribe/complete',
    ADD_COMPANY_NAME: '/company-name',
    HOME: '/home',
    PRODUCTS: '/products',
    PRODUCTS_IMPORT: '/products-import',
    CAMPAIGNS: '/campaigns',
    CAMPAIGNS_CREATE: '/campaigns/create',
    CAMPAIGNS_CREATE_STEP_1: '/campaigns/create/step-1',
    CAMPAIGNS_CREATE_STEP_2: '/campaigns/create/step-2/:id',
    CAMPAIGNS_CREATE_STEP_3: '/campaigns/create/step-3/:id',
    CAMPAIGNS_CREATE_STEP_4: '/campaigns/create/step-4/:id',
    CAMPAIGNS_CREATE_STEP_5: '/campaigns/create/step-5/:id',
    ROOT_CAMPAIGNS_EDIT: '/campaigns/edit',
    CAMPAIGNS_EDIT: '/campaigns/edit/:id',
    LAUNCH_CHECK: '/campaigns/create/launch-check',
    ORDERS: '/orders',
    PERFORMANCE: '/performance',
    TERMS: 'https://www.klickly.com/docs/terms',
    PRIVACY: 'https://www.klickly.com/docs/privacy',
    DO_NOT_SELL: 'https://www.klickly.com/docs/privacy/opt_out',
    LIMIT_USE: 'https://www.klickly.com/docs/disclosure-sensitive-info',
    BLOG: 'https://blog.klickly.com/',
    HELP_CENTER: '/help-center',
    INSIGHTS: '/insights',
    PERKS: '/perks',
    PROFILE: '/profile',
    ACCOUNT_SETTINGS: '/profile/account-settings',
    COMPANY_INFO: '/profile/account-settings/company-info',
    ACCOUNT_MANAGEMENT: '/profile/account-settings/account-management',
    CREATIVE_SETTINGS: '/profile/account-settings/creative-settings',
    INVOICES_SETTINGS: '/profile/account-settings/invoices-settings',
    TRACKING_SETTINGS: '/profile/account-settings/tracking-settings',
    CUSTOMER_MANAGEMENT: '/profile/account-settings/customer-management',
    PAYMENTS_SETTINGS: '/profile/account-settings/payments-settings',
    NOTIFICATIONS_SETTINGS: '/profile/account-settings/notifications-settings',
    INTEGRATIONS_SETTINGS: '/profile/account-settings/integrations-settings',
    RETURN_TO_DASHBOARD: '/return',
    TEAM_MEMBER_INVITE: '/team-member/invite',
    OAUTH_BIGCOMMERCE_CALLBACK: '/oauth/big-commerce/callback',
    APP_EMBEDDED: '/embedded',
    AUTH_APP_EMBEDDED: 'auth/embedded'
};
