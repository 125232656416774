import React, { Fragment } from 'react';
import Header from './components/Header';
import UploadProducts from './components/UploadProducts';

const ProductsImport = () => {
    return (
        <Fragment>
            <Header />
            <UploadProducts />
        </Fragment>
    );
};

export default ProductsImport;
