import React, { Fragment, useState } from 'react';
import { BOTTOM_INDENTS, COLORS, RIGHT_INDENTS } from '@constants';
import { useStore } from '@hooks';
import { Button, Icon, Typography, WhiteCover } from '@ui';
import { Divider, Flex } from 'antd';
import { observer } from 'mobx-react';
import dataURItoBlob from '../../../../utils/dataURItoBlob';
import { StyledIcon, StyledStatusWrapper, StyledUpload } from './styled';

const UploadProducts = observer(() => {
    const [error, setError] = useState(false);
    const [validRecords, setValidRecords] = useState(0);
    const store = useStore();
    const { bulkUploadPending, bulkUpload } = store.productsStore;

    const handleDownloadExample = () => {
        // Create an invisible link to trigger the download
        const link = document.createElement('a');
        link.href = '/bulk_examples/products.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after download
    };

    const beforeUpload = (file) => {
        if (file.size > 1048576) {
            return setError('There was an error uploading the file. Please ensure file is less than 10 MB.');
        }
    };

    const handleChange = ({ file }) => {
        if (file.status === 'uploading') {
            setValidRecords(0);
            setError(false);
            return;
        }
        if (file.status === 'done') {
            const reader = new FileReader();
            reader.onload = async () => {
                const result = reader.result;
                const formData = new FormData();
                formData.append('file', dataURItoBlob(result));
                const response = await bulkUpload(formData);
                handleResponse(response);
            };

            reader.readAsDataURL(file.originFileObj);
        }
    };

    const handleResponse = (response) => {
        if (response.status === 'added-to-queue') {
            setValidRecords(response.validRecords);
            return;
        }

        setError(
            'Upload failed due to a table structure error. See the CSV Structure Guide for the correct structure.'
        );
    };

    return (
        <Fragment>
            <WhiteCover title='Upload via CSV' hasDivider={false}>
                <StyledStatusWrapper $error={error}>
                    <Flex align='center' gap='24px'>
                        <Icon color={COLORS.$gray40Black} size='80px' component={() => <Icon.EntireCatalogIcon />} />
                        <Flex vertical style={{ flexGrow: 1 }}>
                            <Typography.Text type='badgeLarge' style={BOTTOM_INDENTS.XXS}>
                                {validRecords
                                    ? `${validRecords.toLocaleString('en-US')} products added to queue`
                                    : '0 products'}
                            </Typography.Text>
                            {validRecords ? (
                                <Typography.Text type='descriptor1'>
                                    <Icon
                                        size='24px'
                                        component={() => <Icon.CheckCircleIcon />}
                                        style={RIGHT_INDENTS.XXS}
                                    />
                                    Products will appear on the Products page when processing is complete.
                                </Typography.Text>
                            ) : (
                                <Typography.Text type='descriptor1'>
                                    Add products via CSV file (maximum file size — 10MB)
                                </Typography.Text>
                            )}
                            {error ? (
                                <Typography.Text type='descriptor1' color={COLORS.$tartRed}>
                                    {error}
                                </Typography.Text>
                            ) : null}
                        </Flex>
                        <StyledUpload
                            accept='.csv'
                            customRequest={({ file, onSuccess }) => {
                                setTimeout(() => onSuccess('ok'), 0); // Simulates a successful server response
                            }}
                            $pending={bulkUploadPending}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            showUploadList={false}
                        >
                            <Button
                                type='primary'
                                size='large'
                                icon={
                                    <StyledIcon
                                        $pending={bulkUploadPending}
                                        color={COLORS.$white}
                                        size='24px'
                                        component={() => (bulkUploadPending ? <Icon.ResetIcon /> : <Icon.UploadIcon />)}
                                    />
                                }
                            >
                                {validRecords ? 'Update Products' : 'Upload Products'}
                            </Button>
                        </StyledUpload>
                    </Flex>
                </StyledStatusWrapper>
                <Flex align='center' gap='48px'>
                    <Typography.Text type='descriptor1' color={COLORS.$gray60Black}>
                        Processing time: Once uploaded, it may take some time for the system to process your file.
                        You’ll see your updated catalog on the Products page when processing is complete.
                    </Typography.Text>
                    <Typography.Text type='descriptor1' color={COLORS.$gray60Black}>
                        Updating products: You can update existing products by uploading a new CSV with matching
                        productIDs. All corresponding product information will be updated when file upload is complete.
                    </Typography.Text>
                </Flex>
                <Divider margin='24px 0' />
                <Flex justify='space-between' align='center'>
                    <Typography.Title level={2}>Need help formatting your CSV?</Typography.Title>
                    <Flex align='center' gap='16px'>
                        <Button
                            backgroundColor={COLORS.$white}
                            block
                            icon={<Icon color={COLORS.$gray60Black} size='24px' component={() => <Icon.InfoIcon />} />}
                            onClick={() => window.open('/docs/api#tag/product_schema', '_blank')}
                        >
                            View the CSV Structure Guide
                        </Button>
                        <Button
                            backgroundColor={COLORS.$white}
                            block
                            icon={
                                <Icon color={COLORS.$gray60Black} size='24px' component={() => <Icon.DownloadIcon />} />
                            }
                            onClick={handleDownloadExample}
                        >
                            Download template
                        </Button>
                    </Flex>
                </Flex>
            </WhiteCover>
        </Fragment>
    );
});

export default UploadProducts;
