import React, { Fragment, useEffect, useState } from 'react';
import { orderMap } from '@constants';
import { useStore } from '@hooks';
import { Loader, Table } from '@ui';
import { camelToSnakeCase } from '@utils';
import { observer } from 'mobx-react';
import EmptyState from './components/EmptyState';
import Header from './components/Header';
import NotConnected from './components/NotConnected';
import ProductsSyncProgress from './components/ProductsSyncProgress';
import ProductVariantsTable from './components/ProductVariantsTable';
import { useProgress } from './hooks/useProgress';
import { getColumns } from './constants';

const Products = observer(() => {
    const store = useStore();
    const { products, getProducts, pending, meta, updateFilters, updateSorters, resetFilters, resetSorters } =
        store.productsStore;
    const {
        isUninstalled,
        productsSyncProgress,
        currentAccount: { productTypes, platformId },
        getAccountProductTypes,
        isManual
    } = store.accountsStore;
    const [loading, setLoading] = useState(true);
    const [hasProducts, setHasProducts] = useState(false);

    const showProgress = useProgress(setLoading);

    useEffect(() => {
        setLoading(true);
        (async () => {
            if (isUninstalled) {
                setHasProducts(false);
                setLoading(false);
            } else {
                const promises = [getProducts(), !productTypes.length ? getAccountProductTypes() : null].filter(
                    Boolean
                );

                await Promise.all(promises);

                const hasProducts = !!store.productsStore.products.length;
                setHasProducts(hasProducts);

                setLoading(false);
            }
        })();

        return () => {
            resetFilters();
            resetSorters();
        };
    }, [productsSyncProgress?.done]);

    const handleTableChange = (pagination, filters, sorter) => {
        const page = pagination?.current;

        updateSorters({
            sort: sorter.order ? camelToSnakeCase(sorter.field) : undefined,
            order: sorter.order ? orderMap[sorter.order] : undefined
        });

        updateFilters({
            typeIds: filters.type || undefined,
            klickly_statuses: filters.klicklyStatus || undefined,
            ecommerce_statuses: filters.ecommerceStatus || undefined
        });

        getProducts({ page });
    };

    const handleSearch = (search) => {
        updateFilters({
            search: search ? `title:${search}` : undefined
        });
        getProducts();
    };

    if (loading) {
        return <Loader size='120px' />;
    }

    if (isUninstalled) {
        return <NotConnected />;
    }

    if (showProgress) {
        return <ProductsSyncProgress />;
    }

    if (!hasProducts) {
        return <EmptyState />;
    }

    return (
        <Fragment>
            <Header showSearch setSearchValue={handleSearch} showAddProductsButton={isManual} />
            <Table
                data-e2e='products_table'
                dataSource={products}
                columns={getColumns(productTypes, platformId)}
                rowKey={(record) => record.id}
                loading={pending}
                pagination={{
                    pageSize: meta?.perPage,
                    total: meta?.total,
                    totalPages: meta?.totalPages,
                    current: meta?.page,
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
                expandable={{
                    expandedRowRender: (record) => <ProductVariantsTable productId={record.id} />
                }}
                expandPosition='left'
                scroll={{
                    x: 1250
                }}
            />
        </Fragment>
    );
});

export default Products;
