import React, { Fragment } from 'react';
import { Typography } from '@ui';
import { Divider } from 'antd';

const Header = () => {
    return (
        <Fragment>
            <Typography.Text type='em0'>Import Products</Typography.Text>
            <Divider margin='48px 0' />
        </Fragment>
    );
};

export default Header;
