import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import { useStore } from '@hooks';
import { Button, Typography } from '@ui';
import { Flex } from 'antd';
import { observer } from 'mobx-react';
import Header from '../Header';

const EmptyState = observer(() => {
    const navigate = useNavigate();
    const store = useStore();
    const { isManual } = store.accountsStore;

    return (
        <Flex data-e2e='products_empty_section' vertical style={{ flexGrow: 1 }}>
            <Header />
            <Flex align='center' justify='center' style={{ flexGrow: 1 }}>
                {isManual ? (
                    <Flex vertical align='center' justify='center' style={{ maxWidth: 600 }}>
                        <Typography.Title level={2} color={COLORS.$gray60Black} style={BOTTOM_INDENTS.XXS}>
                            Import your product catalog to get started
                        </Typography.Title>
                        <Typography.Text
                            align='center'
                            type='descriptor1'
                            color={COLORS.$gray60Black}
                            style={BOTTOM_INDENTS.M}
                        >
                            Your product catalog needs to be imported to enable marketing campaigns. You can import
                            products via webhooks and API endpoints (allows real-time product updates) or via CSV upload
                            (not recommended for ongoing product management).
                        </Typography.Text>
                        <Flex align='center' gap='24px'>
                            <Button
                                style={{ width: 150 }}
                                backgroundColor={COLORS.$white}
                                block
                                onClick={() => window.open('/docs/api#tag/products', '_blank')}
                            >
                                Connect via API
                            </Button>
                            <Button
                                style={{ width: 150 }}
                                backgroundColor={COLORS.$white}
                                block
                                onClick={() => navigate(ROUTE_PATHS.PRODUCTS_IMPORT)}
                            >
                                Upload via CSV
                            </Button>
                        </Flex>
                    </Flex>
                ) : (
                    <Typography.Title data-e2e='products_no-products_text' level={2} color={COLORS.$gray60Black}>
                        You have no products
                    </Typography.Title>
                )}
            </Flex>
        </Flex>
    );
});

export default EmptyState;
