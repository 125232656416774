import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom';
import { ErrorNotificationWrapper, NotificationProvider, ScrollTopWrapper } from '@components';
import { ROUTE_PATHS } from '@constants';
import InviteeUser from '@pages/InviteeUser';
import ConsentNotification from './components/ConsentNotification';
import AppEmbedded from './pages/AppEmbedded';
import CampaignsPage from './pages/CampaignsPage';
import CampaignWizardLayout from './pages/CampaignWIzard/Layout';
import EditCampaign from './pages/CampaignWIzard/pages/CampaignEdit';
import LaunchCheck from './pages/CampaignWIzard/pages/LaunchCheck';
import Step1 from './pages/CampaignWIzard/pages/Step1';
import Step2 from './pages/CampaignWIzard/pages/Step2';
import Step3 from './pages/CampaignWIzard/pages/Step3';
import Step4 from './pages/CampaignWIzard/pages/Step4';
import Step5 from './pages/CampaignWIzard/pages/Step5';
import CompanyName from './pages/CompanyName';
import Connector from './pages/Connector';
import Docs from './pages/Docs';
import ErrorBoundary from './pages/ErrorBoundary';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordReset from './pages/ForgotPassword/components/ForgotPasswordReset';
import ForgotPasswordSent from './pages/ForgotPassword/components/ForgotPasswordSent';
import ForgotPasswordSuccess from './pages/ForgotPassword/components/ForgotPasswordSuccess';
import HelpCenter from './pages/HelpCenter';
import Home from './pages/Home';
import Insights from './pages/Insights';
import LandingPage from './pages/Landing';
import Login from './pages/Login';
import OauthBigCommerceCallback from './pages/Oauth/BigCommerce/Callback';
import Orders from './pages/Orders';
import Page404 from './pages/Page404';
import Performance from './pages/Performance';
import Perks from './pages/Perks';
import Products from './pages/Products';
import ProductsImport from './pages/ProductsImport';
import Profile from './pages/Profile';
import AccountSettings from './pages/Profile/components/AccountSettings';
import AccountSettingsLayout from './pages/Profile/components/AccountSettings/AccountSettingsLayout';
import AccountManagement from './pages/Profile/components/AccountSettings/components/AccountManagement';
import CompanyInfo from './pages/Profile/components/AccountSettings/components/CompanyInfo';
import CreativeSettings from './pages/Profile/components/AccountSettings/components/CreativeSettings';
import CustomerManagement from './pages/Profile/components/AccountSettings/components/CustomerManagement';
import Integrations from './pages/Profile/components/AccountSettings/components/Integrations';
import Invoices from './pages/Profile/components/AccountSettings/components/Invoices';
import Notifications from './pages/Profile/components/AccountSettings/components/Notifications';
import Payments from './pages/Profile/components/AccountSettings/components/Payments';
import Tracking from './pages/Profile/components/AccountSettings/components/Tracking';
import Register from './pages/Register';
import RequestAccess from './pages/RequestAccess';
import Return from './pages/Return';
import Root from './pages/Root';
import Unsubscribe from './pages/Unsubscribe';
import Welcome from './pages/Welcome';
import RequireAuth from './routes/RequireAuth';
import RequireUnAuth from './routes/RequireUnAuth';
import { MainLayout } from './layouts';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={
                <ErrorBoundary>
                    <NotificationProvider>
                        <ConsentNotification>
                            <ErrorNotificationWrapper>
                                <ScrollTopWrapper />
                            </ErrorNotificationWrapper>
                        </ConsentNotification>
                    </NotificationProvider>
                </ErrorBoundary>
            }
        >
            <Route path={ROUTE_PATHS.ADD_COMPANY_NAME} element={<CompanyName />} />
            <Route path={ROUTE_PATHS.APP_EMBEDDED} element={<AppEmbedded />} />
            <Route path={ROUTE_PATHS.OAUTH_BIGCOMMERCE_CALLBACK} element={<OauthBigCommerceCallback />} />
            <Route path={ROUTE_PATHS.REGISTER} element={<Register />} />
            <Route path={ROUTE_PATHS.TEAM_MEMBER_INVITE} element={<InviteeUser />} />
            <Route path={ROUTE_PATHS.REQUEST_ACCESS} element={<RequestAccess />} />
            <Route path={ROUTE_PATHS.CONNECTOR} element={<Connector />} />
            <Route path={ROUTE_PATHS.UNSUBSCRIBE} element={<Unsubscribe />} />
            <Route path={ROUTE_PATHS.WELCOME} element={<Welcome />} />
            <Route path={ROUTE_PATHS.DOCS} element={<Docs />} />
            <Route element={<RequireUnAuth />}>
                {/* Route register user */}
                <Route path={ROUTE_PATHS.ROOT} element={<LandingPage />} />
                <Route path={ROUTE_PATHS.LOGIN} element={<Login />} />
                <Route path={ROUTE_PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTE_PATHS.FORGOT_PASSWORD_RESET} element={<ForgotPasswordReset />} />
                <Route path={ROUTE_PATHS.FORGOT_PASSWORD_SENT} element={<ForgotPasswordSent />} />
                <Route path={ROUTE_PATHS.FORGOT_PASSWORD_SUCCESS} element={<ForgotPasswordSuccess />} />
            </Route>
            <Route
                element={
                    <RequireAuth>
                        <MainLayout>
                            <Outlet />
                        </MainLayout>
                    </RequireAuth>
                }
            >
                {/* Route register account */}
                <Route path={ROUTE_PATHS.ROOT} element={<Root />} />
                <Route path={ROUTE_PATHS.HOME} element={<Home />} />
                <Route path={ROUTE_PATHS.PROFILE} element={<Profile />} />
                <Route path={ROUTE_PATHS.RETURN_TO_DASHBOARD} element={<Return />} />

                <Route path={ROUTE_PATHS.PRODUCTS} element={<Products />} />
                <Route path={ROUTE_PATHS.PRODUCTS_IMPORT} element={<ProductsImport />} />
                <Route path={ROUTE_PATHS.CAMPAIGNS} element={<CampaignsPage />} />
                <Route element={<CampaignWizardLayout />}>
                    <Route path={ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1} element={<Step1 />} />
                    <Route path={ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_2} element={<Step2 />} />
                    <Route path={ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_3} element={<Step3 />} />
                    <Route path={ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_4} element={<Step4 />} />
                    <Route path={ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_5} element={<Step5 />} />
                    <Route path={`${ROUTE_PATHS.LAUNCH_CHECK}/:id`} element={<LaunchCheck />} />
                    <Route path={ROUTE_PATHS.CAMPAIGNS_EDIT} element={<EditCampaign />} />
                </Route>

                <Route path={ROUTE_PATHS.ORDERS} element={<Orders />} />
                <Route path={ROUTE_PATHS.PERFORMANCE} element={<Performance />} />
                <Route path={ROUTE_PATHS.INSIGHTS} element={<Insights />} />
                <Route path={ROUTE_PATHS.PERKS} element={<Perks />} />
                <Route path={ROUTE_PATHS.HELP_CENTER} element={<HelpCenter />} />

                <Route element={<AccountSettingsLayout />}>
                    <Route path={ROUTE_PATHS.ACCOUNT_SETTINGS} element={<AccountSettings />} />
                    <Route path={ROUTE_PATHS.COMPANY_INFO} element={<CompanyInfo />} />
                    <Route path={ROUTE_PATHS.ACCOUNT_MANAGEMENT} element={<AccountManagement />} />
                    <Route path={ROUTE_PATHS.CREATIVE_SETTINGS} element={<CreativeSettings />} />
                    <Route path={ROUTE_PATHS.INVOICES_SETTINGS} element={<Invoices />} />
                    <Route path={ROUTE_PATHS.PAYMENTS_SETTINGS} element={<Payments />} />
                    <Route path={ROUTE_PATHS.NOTIFICATIONS_SETTINGS} element={<Notifications />} />
                    <Route path={ROUTE_PATHS.CUSTOMER_MANAGEMENT} element={<CustomerManagement />} />
                    <Route path={ROUTE_PATHS.TRACKING_SETTINGS} element={<Tracking />} />
                    <Route path={ROUTE_PATHS.INTEGRATIONS_SETTINGS} element={<Integrations />} />
                </Route>
            </Route>
            <Route path='*' element={<Page404 />} />
        </Route>
    )
);
