import React, { Fragment } from 'react';
import { COLORS, ROUTE_PATHS } from '@constants';
import { Button, Typography } from '@ui';
import { Divider, Flex } from 'antd';

const AddProductsModal = ({ handleNavigate }) => {
    return (
        <Fragment>
            <Typography.Text type='descriptor1'>
                To keep your catalog updated in real-time, we highly recommend integrating via webhooks or API
                endpoints. While CSV uploads are available, they are not recommended for ongoing product management.
            </Typography.Text>
            <Divider margin='0 24px' />
            <Flex justify='end' align='center' gap='16px'>
                <Button
                    style={{ width: 143 }}
                    backgroundColor={COLORS.$white}
                    block
                    onClick={() => handleNavigate(ROUTE_PATHS.PRODUCTS_IMPORT)}
                >
                    Upload via CSV
                </Button>
                <Button
                    style={{ width: 162 }}
                    type='primary'
                    block
                    onClick={() => handleNavigate(ROUTE_PATHS.INTEGRATIONS_SETTINGS)}
                >
                    Review API setup
                </Button>
            </Flex>
        </Fragment>
    );
};

export default AddProductsModal;
