import { useEffect, useState } from 'react';
import { cookieConsent } from '../utils/cookieConsent';

export const useCookieConsent = () => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        setShowNotification(!cookieConsent.hasCookiePreference());

        const handleStorageChange = (e) => {
            if (e.key === 'cookie_consent') {
                setShowNotification(!cookieConsent.hasCookiePreference());
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const setCookiePreference = (preference) => {
        cookieConsent.setCookie(preference);
        setShowNotification(false);
    };

    return {
        showNotification,
        acceptCookies: () => setCookiePreference('accepted'),
        rejectCookies: () => setCookiePreference('rejected')
    };
};
