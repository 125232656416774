import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BOTTOM_INDENTS, COLORS, ROUTE_PATHS } from '@constants';
import {
    CUSTOMERS_STATUS,
    ORDERS_STATUS,
    PRODUCT_CATALOG_STATUS,
    PURCHASE_EVENT_STATUS,
    SCRIPT_TAG_STATUS
} from '@enums';
import { usePolling, useStore } from '@hooks';
import { Button, Icon, Typography, WhiteCover } from '@ui';
import { observer } from 'mobx-react';
import CoverTitle from '../../../../../../../../components/IntegrationKlickly/components/CoverTitle';
import LineItem from './shared/LineItem';
import { StyledConfirm, StyledError, StyledLink } from './styled';

const StoreSetupManual = observer(() => {
    const [modal, setModal] = useState(null);
    const navigate = useNavigate();
    const store = useStore();
    const {
        setupInfo,
        manualStoreSetupFinished,
        getSetupInfo,
        historicalSetupOrdersPending,
        historicalSetupCustomersPending
    } = store.accountsStore;

    const { startPolling, stopPolling } = usePolling(getSetupInfo, 10000, false);

    useEffect(() => {
        if (
            setupInfo.purchaseEventStatus === PURCHASE_EVENT_STATUS.PENDING ||
            setupInfo.scriptTag.status === SCRIPT_TAG_STATUS.PENDING
        ) {
            startPolling();
        } else {
            stopPolling();
        }
    }, [setupInfo.purchaseEventStatus, setupInfo.scriptTag.status]);

    const setupConfig = {
        'api-setup': {
            title: 'API setup',
            viewMode: true
        },
        'analytics-script': {
            title: 'Analytics script setup',
            status: setupInfo.scriptTag.status
        },
        'purchase-event': {
            title: 'Purchase events setup',
            status: setupInfo.purchaseEventStatus
        },
        'product-catalog': {
            title: 'Product catalog setup',
            status:
                setupInfo.productCatalog > 0 ? PRODUCT_CATALOG_STATUS.CONNECTED : PRODUCT_CATALOG_STATUS.NOT_CONNECTED
        },
        orders: {
            title: 'Orders setup',
            status: historicalSetupOrdersPending
                ? ORDERS_STATUS.PENDING
                : setupInfo.historical.hasOrders
                ? ORDERS_STATUS.CONNECTED
                : ORDERS_STATUS.NOT_CONNECTED
        },
        customers: {
            title: 'Customers setup',
            status: historicalSetupCustomersPending
                ? CUSTOMERS_STATUS.PENDING
                : setupInfo.historical.hasCustomers
                ? CUSTOMERS_STATUS.CONNECTED
                : CUSTOMERS_STATUS.NOT_CONNECTED
        }
    };

    return (
        <WhiteCover
            customTitle={<CoverTitle title='Connect your store to Klickly' icon={<Icon.KlicklyLogo />} />}
            style={BOTTOM_INDENTS.M}
            rightContent={
                <Button
                    type='link'
                    data-e2e='integrations_api-integration-guide_btn'
                    padding='0'
                    onClick={() => window.open('/docs/api', '_blank')}
                >
                    API integration guide
                </Button>
            }
        >
            <Typography.Text type='body2' color={COLORS.$gray60Black} style={BOTTOM_INDENTS.M}>
                Connecting your store allows you to synchronize your shopping cart data with Klickly’s platform, which
                enables updates you make to products, prices, and inventory to flow through automatically to Klickly,
                and powers in-ad buying and accurate commission charging. Please coordinate with your technical team to
                complete this step, as it involves technical knowledge for proper execution. If needed, refer to our
                detailed{' '}
                <StyledLink onClick={() => window.open('/docs/api', '_blank')}>API integration guide</StyledLink> for
                assistance.
            </Typography.Text>

            {Object.entries(setupConfig).map(([key, data]) => {
                return <LineItem setModal={setModal} modal={modal} key={key} setupKey={key} data={data} />;
            })}

            {manualStoreSetupFinished ? (
                <StyledConfirm align='center' justify='space-between'>
                    <Typography.Text type='descriptor1' color={COLORS.$robinBlue}>
                        Your store has been connected to Klickly!
                    </Typography.Text>
                    <Button
                        onClick={() => navigate(ROUTE_PATHS.CAMPAIGNS_CREATE_STEP_1)}
                        type='primary'
                        size='large'
                        iconRight={<Icon size='24px' color={COLORS.$white} component={() => <Icon.PlusIcon />} />}
                    >
                        Add New Campaign
                    </Button>
                </StyledConfirm>
            ) : (
                <StyledError>
                    <Typography.Text type='descriptor1' color={COLORS.$tartRed}>
                        Please complete all required setup steps
                    </Typography.Text>
                </StyledError>
            )}
        </WhiteCover>
    );
});

export default StoreSetupManual;
