import React, { useRef } from 'react';
import { SearchSuffix } from '@components';
import { BOTTOM_INDENTS, COLORS, LEFT_INDENTS } from '@constants';
import { useDebounce } from '@hooks';
import { Button, Icon, Input, Typography } from '@ui';
import { Col, Row } from 'antd';
import { StyledIcon } from '../../../ProductsImport/components/UploadProducts/styled';
import { useAddProductsModal } from '../../hooks/useAddProductsModal';

const Header = ({ showSearch, setSearchValue, showAddProductsButton }) => {
    const ref = useRef(null);

    const handleSearch = useDebounce((e) => {
        setSearchValue(e.target.value);
    }, 1000);

    const { openModal } = useAddProductsModal();

    return (
        <Row justify='space-between' align='middle' style={BOTTOM_INDENTS.XL_PLUS}>
            <Col>
                <Typography.Text data-e2e='products_page_title' type='em0'>
                    Products
                </Typography.Text>
            </Col>
            <Col flex='auto' style={{ minWidth: '518px' }}>
                <Row justify='end' wrap={false} align='middle'>
                    {showSearch && (
                        <Col data-e2e='products_search_bar' flex={showAddProductsButton ? '314px' : '526px'}>
                            <Input
                                data-e2e='products_search_input'
                                ref={ref}
                                value={ref?.current?.value}
                                label='Search products'
                                onChange={handleSearch}
                                suffix={<SearchSuffix inputRef={ref} onSetSearchValue={setSearchValue} />}
                            />
                        </Col>
                    )}
                    {showAddProductsButton && (
                        <Col flex='204px' style={LEFT_INDENTS.M}>
                            <Button
                                type='primary'
                                size='large'
                                iconRight={
                                    <StyledIcon color={COLORS.$white} size='24px' component={() => <Icon.PlusIcon />} />
                                }
                                onClick={openModal}
                            >
                                Add New Products
                            </Button>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default Header;
