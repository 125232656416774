import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '@hooks';
import AddProductsModal from '../components/AddProductsModal';

export const useAddProductsModal = () => {
    const { open, close } = useModal();
    const navigate = useNavigate();

    const handleNavigate = (props) => {
        navigate(props);
        close();
    };

    const openModal = () =>
        open({
            title: <span>Add New Products</span>,
            content: <AddProductsModal handleNavigate={handleNavigate} />,
            addStyle: {
                marginBottom: '24px'
            },
            settings: {
                width: '630px'
            }
        });

    return { openModal };
};
